<template>
  <div>
    <div v-if="imgBoxBlock==false?true:false">
      <div class="topHead" v-if="(appName)=='看埇桥'">
      <div class="topText">
         <img src="../../assets/logo_yongqiao.png"/>
          <span>看埇桥</span>
      </div>
      <div class="back_button" @click="openWx()">
          打开
        </div>
    </div>
    <div :class="{ voluntaryRecruitmentDetail: true, heightStyle: photoShow }"  v-if="volunteerData">
    <div class="head_img">
      <img src="../../assets/images/zhaomu.png" alt />
    </div>
    <div class="title">{{ volunteerData.activityName }}</div>
    <ul class="volunteerList">
      <li>
        <span>所属组织</span>
        <span>{{ volunteerData.regionName }}</span>
      </li>
      <li>
        <span>开始时间</span>
        <span>{{ volunteerData.activityBeginTime }}</span>
      </li>
      <li>
        <span>结束时间</span>
        <span>{{ volunteerData.activityEndTime }}</span>
      </li>
      <li>
        <span>联系人</span>
        <span>{{ volunteerData.leaderName }}</span>
      </li>
      <li>
        <span>联系电话</span>
        <span>{{ volunteerData.leaderPhone }}</span>
      </li>
      <li>
        <span>活动地点</span>
        <span>{{ volunteerData.activityLocation }}</span>
      </li>
      <li>
        <span>招募人数</span>
        <span>{{ volunteerData.recruitPeople }}</span>
      </li>
    </ul>
    <div class="volunteerContent">
      <h3>活动简介</h3>
      <p>{{ volunteerData.summary }}</p>
    </div>
    </div>
    </div>
 <div class="wxPic" v-if="imgBoxBlock==true?true:false">
    <img src="../../assets/openLink.jpg">
  </div>

  </div>
  
</template>

<script>
import Vue from "vue";
import SparkMD5 from "spark-md5";
import OSS from "ali-oss";
import { Button, Toast } from "vant";
import { reject } from "q";
import Recruitapi from "@/api/recruit";
import menuapi from "@/api/contentlibrary";
import {shareData} from '@/api/share'
import tobbarVue from '../tobbar.vue';
import wx from 'weixin-js-sdk';
Vue.use(Button);
Vue.use(Toast);

export default {
  data() {
    return {
      imgBoxBlock:false,
      volunteerData: null,
      success_show: false,
      memberData: null, // 报名的人数情况
      joinLoading: false, // 报名按钮的加载状态
      cancelLoading: false, // 取消报名按钮的加载状态
      // 包含活动id和用户id的参数
      params: {
        memberId: '',
        activityId: ''
      },
      btn_show: false, // 根据用户报名状态显示报名或取消报名按钮
      camera_show: false, //是否显示上传图片按钮
      photoShow: false, // 显示上传的图片，只有从我负责的活动点击进来才会显示这些图片
      signupShow: false,   // 是否显示报名按钮
      allfiles: [],
      is_disable: false, // 是否禁用上传图片按钮
      photoList: [],
      bigPhotoShow: false,
      overlayUrl: '', // 遮罩层中的url链接
      loadShow: false, // 上传loading显示
    };
  },
  created() {
    // 文件上传配置文件
    this.client = new OSS({
      region: "oss-cn-hangzhou",
      accessKeyId: "LTAI4GHnMJwqTzsBkGQputTy",
      accessKeySecret: "UtZpduRDDcpUD6mKUseXDNjKeP5SxQ",
      bucket: "fmp-civ-test",
    });
  },
  mounted() {
    // console.log(this.$route.query);
    this.params.memberId = window.localStorage.userinfo ? JSON.parse(window.localStorage.userinfo).id : '';
    this.params.activityId = this.$route.query.id;
    this.getVolList(this.params);
   
  },
  methods: {
  //打开微信
    openWx(){
        var WX = /(micromessenger)/i;
        var USER_Agent = navigator.userAgent;
        if (WX.test(USER_Agent)) {
            this.imgBoxBlock = true; //提示用默认浏览器打开
            return;
        } else { //协议
            window.location.href = "yqapp://kanyongqiao/openwith?id=" + this.volunteerData.id +"&title=" + this.volunteerData.name+"&fromType=activity" ; 
        }   
        //如果没有唤起app则3秒后会跳转到下载地址，反之则不会跳转到下载地址。
        setTimeout(function () {
            window.location.href = "http://www.yongqiaonews.com/apk/";//下载地址
        }, 3000);
    },
    // 获取微信的token值
    wxinTokenLink(){
          var url=window.location.href;
          //console.log(url)
          var link=window.location.href.split('#/')[0]+"?#/wxShare_voluntaryRec?id="+this.volunteerData.id;
          var appName=this.appName;
          var title=this.volunteerData.summary.replace(/&nbsp;/g,"");
         shareData({url:url,appName:appName}).then((data)=>{
            // 通过config接口注入权限验证配置 
            //console.log(data.data)
            var imgSrc=data.data.avatar;
               wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: data.data.appid, // 必填，公众号的唯一标识
                    timestamp: data.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.data.noncestr, // 必填，生成签名的随机串
                    signature: data.data.signature,// 必填，签名
                    jsApiList: ['updateAppMessageShareData','updateTimelineShareData'] // 必填，需要使用的JS接口列表
                });
                // 通过ready接口处理成功验证
                wx.ready(function (){   //需在用户可能点击分享按钮前就先调用
                wx.updateAppMessageShareData({ 
                        title:title,  // 分享标题
                        desc:title,   // 分享描述
                        link:link,   // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl:imgSrc, // 分享图标
                    }),
                wx.updateTimelineShareData({ 
                        title:title,  // 分享标题
                        desc:title,   // 分享描述
                        link:link,   // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl:imgSrc, // 分享图标
                    })
                });

          })
          .catch((err)=>{
            //console.log(err)
          })
    },
      // 分享
    // shareFun(){
    //     var describe =this.volunteerData.summary.replace(/&nbsp;/g,"");
    //     console.log('分享描述',describe)
    //     var link;
    //     link=window.location.href.split("#/")[0]+".#/wxShare_voluntaryRec?id="+this.volunteerData.id;
    //     return {url:link,title:describe,describe:describe};
    //     // this.shareContent({url: link,title:this.detailAll.title,thumb:this.detailAll.thumb.split(',')[0],describe:describe});
    // },
   
    // 招募活动详情
    getVolList(params) {
      Recruitapi.getshareRecruitById(params)
        .then((res) => {
          // console.log(res.data);
          // 判断是否显示照片上传框
          if (this.$route.query.signOfMy && res.data.status !== 5) {
            this.camera_show = true;
          }
          if (this.$route.query.signOfMy) {
            this.photoShow = true;
          }
          if (!this.$route.query.signOfMy && res.data.recruitType) {
            this.signupShow = true;
          } else {
            this.signupShow = false;
          }
          // alert(res.data.isSignUp);
          this.volunteerData = res.data;
          this.wxinTokenLink()
          if (res.data.isSignUp) {
            this.btn_show = false;
          } else {
            this.btn_show = true;
          }
          // 活动结束，禁用上传按钮
          if (res.data.status === 5) {
            this.is_disable = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
       
    },
    
    // 查看大图
    lookBigPhoto(url) {
      this.overlayUrl = url;
      this.bigPhotoShow = true;
    },
    // 此时可以自行将文件上传至服务器
    afterRead(file) {
      // console.log(file);
    },
   
    // 截取数组地址
    _getResult(resUrl) {
      const _arr = [];
      for (let i = 0; i < resUrl.length; i++) {
        _arr.push(resUrl[i].split("?")[0]);
      }
      return _arr;
    },
    _fileSuccess(file, url) {
      file.Location = url; // 到内容库
      this.fileInLibrary(file); // 入库
    },
    // 入内容库
    fileInLibrary(file) {
      var data = {
        mediaMD5: file.MD5,
        mediaName: file.name,
        mediaParentId: file.mediaParentId,
        regionId: file.regionId,
        userId: file.userId,
        mediaSize: file.size,
        mediaSuffix: file.suffix,
        originalMediaUrl: file.Location,
      };
      // console.log(data);
      menuapi
        .warehousing(data)
        .then((res) => {
          //console.log(res);
          if (res.success) {
            Toast('上传完成')
            this.finishAcitivity();
            this.is_disable = false;
            this.loadShow = false;
          } else {
            Toast("上传失败,请重新上传!");
            this.is_disable = false;
            this.loadShow = false;
          }
        })
        .catch(() => {
          Toast("上传失败,请重新上传!");
          this.is_disable = false;
          this.loadShow = false;
        });
    },
    // 完成活动
    finishAcitivity() {
      this.allfiles = [];
      this.getPhoto();
    },
    onDelele() {
      this.loadShow = false;
      this.is_disable = false;
    }
  },
};
</script>

<style lang="less" scoped>
.wxPic{  
  img{
    width:100%;
    height:100%;
    margin-top: 1rem;
  }
}
.topHead{
  width: 100%;
  height: 40px;
  display: flex;
  position:fixed;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 5px 5px 5px #DADADA;
  align-items: center;
  .topText{
    margin-left:10px ;
    span{
      line-height: 80px;
    }
    img{
      width: 35px;
      transform: translateY(11px);
    }

  }
  .back_button{
      margin-right:20px ;
      background-color: red;
      height:30px;
      border-radius: 15px;
      width: 1.6rem;
      font-size: 15px;
      color: #fff;
      text-align: center;
      line-height:30px ;
    }
}
.voluntaryRecruitmentDetail {
  background: #fff;
  font-size: 0.28rem;
  // padding: 3vw 3vw 8vw 3vw;
  padding-bottom: 3vw;
  padding-left: 3vw;
  padding-right: 3vw;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 0.36rem;
    font-weight: 700;
  }
  .head_img {
    margin-top: 13vw;
    img {
      width: 100%;
      height: 25vw;
    }
  }
  .volunteerList {
    color: rgb(94, 94, 94);
    li {
      font-size: 4vw;
      margin: 0.2rem 0;
      span {
        display: inline-block;
        &:nth-child(1) {
          width: 1.5rem;
          color: #8a8a8a;
          margin-right: 5vw;
        }
      }
    }
  }
  .volunteerContent {
    // height: 100vw;
    h3 {
      position: relative;
      // padding-left: 0.2rem;
      margin: 0.2rem 0;
      // &::before {
      //     content: "";
      //     display: blcok;
      //     width: 0.1rem;
      //     height: 0.4rem;
      //     background-color: #f40;
      //     position: absolute;
      //     left: 0px;
      // }
    }
    p {
      word-break: break-all;
      color: #707070;
      line-height: 6.5vw;
      // padding-bottom: 38vw;
    }
  }
  .bottom_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 10px 20px 10px;
    button {
      // width: 25vw;
      height: 7vw;
      border-radius: 15px;
      border: none;
      line-height: 7vw;
      // background: #36B313;
      color: #fff;
    }
  }
  .photo_title {
    font-size: 1.17em;
    font-weight: bold;
    margin-top: 10vw;
    position: relative;
    .loading {
      position: absolute;
      bottom: 35px;
    }
  }
  .show_photo {
    // background: red;
    // margin-top: 15vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // justify-content: space-between;
    img {
      width: 28vw;
      margin: 3px;
    }
  }
  .photo_wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100vw;
      }
    }
  }
  .wrapper {
    .success_block {
      display: flex;
      justify-content: center;
      flex-flow: column;
      align-items: center;
      background: #fff;
      width: 80vw;
      margin: 50% auto 0;
      border-radius: 10px;
      .success_head {
        width: 40vw;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4vw;
        img {
          width: 50vw;
        }
      }
      p {
        font-size: 7vw;
        color: #00a0e9;
        font-weight: bold;
        margin-bottom: 1vw;
      }
      button {
        border-radius: 6px;
        border: none;
        width: 60vw;
        height: 10vw;
        line-height: 10vw;
        text-align: center;
        background: #00a0e9;
        color: #fff;
        font-size: 4vw;
        margin: 5vw 0;
      }
    }
  }
  .btn {
    justify-self: flex-end;
  }
}
.heightStyle {
  height: auto;
}
</style>