import request from '@/utils/request'

// 招募页活动列表
export function shareData(data) {
  return request({
    url: `/v2/api/app/ap_contribution//wxShare/sendMessageWx`,
    method: 'post',
    data
  })
}

export default { shareData }