import { render, staticRenderFns } from "./wxShare_voluntaryRec.vue?vue&type=template&id=2187622c&scoped=true&"
import script from "./wxShare_voluntaryRec.vue?vue&type=script&lang=js&"
export * from "./wxShare_voluntaryRec.vue?vue&type=script&lang=js&"
import style0 from "./wxShare_voluntaryRec.vue?vue&type=style&index=0&id=2187622c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2187622c",
  null
  
)

export default component.exports